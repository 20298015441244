import React, { useState, useEffect, useRef } from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import VideoCard from '@components/modules/LatestVideos/videoCard';
import Col from "react-bootstrap/Col";
import { Stack } from "react-bootstrap";
import BookValuation from '@components/modules/BookValuation';
import { StaticImage } from "gatsby-plugin-image";
import { Routes } from '@components/routes';
import Loader from '@components/loader';
import { imageBannerFragmentGql } from "@components/gql";
import Button from "react-bootstrap/Button";
import VideoDetails from "../../templates/video-details"
import { contentItem } from '@components/utils/animations';

const sliderConfig = {
    slidesPerView: "auto",
    spaceBetween: 32,
    pagination: false,
    breakpoints: {
        1200: {
            slidesPerView: "auto",
            spaceBetween: 40
        }
    }
}

const AfterTitle = ({ categories, filter, clickFilter  }) => {
    return(
        <>
        
            {categories?.length > 0 &&
                <div variants={contentItem} className="mt-32 mt-md-40 mt-lg-56">
                    <Carousel className="image-banner-btn__carousel" {...sliderConfig}>
                    <SwiperSlide>
                                        <Button onClick={() => clickFilter('All')} variant={filter === "All" ? "link-secondary-underlined" : "link-secondary"}>All Categories</Button>
                                    </SwiperSlide>
                        {categories.map((item, i) => {
                            // let addedBtnClass = {
                            //     to: "#",
                            //     variant: tags_refetched.length === 1 ? blog === tags_refetched[0] ? "link-secondary-underlined" : "link-secondary" : "link-secondary",
                            //     label: `${blog}`,
                            //     onClick: () => refetch({ tagsInput: `${blog}` })
                            // }
                                   
                                return(
                                    <SwiperSlide key={i}>
                                        <Button onClick={() => clickFilter(item.Name)} variant={filter === item.Name ? "link-secondary-underlined" : "link-secondary"}>{item.Name}</Button>
                                    </SwiperSlide>
                                )
                           
                        })}
                    </Carousel>
                </div>
            }
        </>
    )
}
const VideoLandingPages = ({
    pageContext,
    // data,
    location
}) => {
    const GET_ALL_VIDEOS_LANDING = gql`
    query {
        pages(publicationState: LIVE, where: {PageTemplate: "VideoLanding"}) {
            Pagename
            Title
            Slug
            BannerImage {
                ...ImageBannerFragmentGql
            }
            Metadata {
                title
                description
                image {
                    url
                }
            }
        }
        categories(publicationState: LIVE){
            Name
            Publish
          }
        videos(publicationState: LIVE, sort: "Date:DESC") {
            Slug
            Date
            id
            Categories {
                Name
              }
            BannerImage {
                ... on ComponentModulesBannerImage {
                    id
                    VideoUrl
                    ItemText
                    ItemTitle
                    id
                    Image {
                        alternativeText
                        url
                    }
                }
            }
        }
    }
    ${imageBannerFragmentGql}
`;

const { loading, error, data } = useQuery(GET_ALL_VIDEOS_LANDING, {
    variables: { id: pageContext?.id }
});
const myRef = React.createRef();
const [filter, setFilter] = useState("All")
const clickFilter = (e) => {
    setFilter(e)
    var arr = [];
    data?.videos?.filter(list => list.Categories?.map(c => c.Name).includes(e)).map((item, index) => {
        arr.push(item)
    })
    setVideos(e === "All" ? data?.videos : arr)
    myRef.current.scrollIntoView({ behavior: 'smooth'})
}
// console.log(data)
const [videos, setVideos] = useState([])
const [postsToShow, setPostsToShow] = useState([]);
const [next, setNext] = useState(8);
const postsPerPage = 8;
let arrayForHoldingPosts = [];

const loopWithSlice = (start, end) => {
    const slicedPosts = videos?.slice(0, end);
    if (videos) {
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    }

};

const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
};
useEffect(() => {
    loopWithSlice(0, postsPerPage);
    setNext(8)
}, [videos?.length]);
useEffect(() => {
    setVideos(data?.videos)
}, [data]);
    let myvideoslug = ''
    let pathUri_main = location.pathname.split("/media/uae-property-videos/");
  
    if ( pathUri_main[1] ) {
      let pathUri_arr = pathUri_main[1].split("?")[0].split("/")
      myvideoslug = pathUri_arr[0];
    }
    
    if (myvideoslug) {
        return(
          <VideoDetails myslug={myvideoslug}  />
        )
        
    }
    else {
    

    const breadcrumbs = [
        { label: "Media", url: `${Routes.MediaPage}` },
        { label: data?.pages[0]?.Pagename, url: `${Routes.MediaPage}/${data?.pages[0]?.Slug}` }
    ]

  

   

    return (
        <Layout popularSearch={"Media_Layout"}>
            <Loader loading={loading} error={error} hideText={true} fullScreen>
                <Seo
                    title={data?.pages[0]?.Metadata?.title ? data?.pages[0].Metadata.title : data?.pages[0]?.Title}
                    description={data?.pages[0]?.Metadata?.description ? data?.pages[0].Metadata.description : null}
                    image={data?.pages[0]?.Metadata?.image?.url_sharp?.childImageSharp ? data?.pages[0].Metadata.image.url_sharp.childImageSharp.resize : null}
                />
                <BannerRender
                    page={data?.pages[0]}
                    breadcrumbs={breadcrumbs}
                    afterTitle={<AfterTitle categories={data?.categories} clickFilter={clickFilter} filter={filter} />}
                />
                <div ref={myRef} className={"scrolling-pad"}/>
                <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                    <Stack className="gap-20 gap-md-40 flex-md-row mb-40">
                        <div>Showing {videos?.length} {videos?.length > 1 ? 'videos' : 'video'}</div>
                        <a href="https://www.youtube.com/c/Exclusive-links" target="_blank" rel="noreferrer" className="d-block ms-md-auto">
                            <StaticImage
                                src="../../images/youtube.png"
                                width={92}
                                quality={19}
                                formats={["auto", "webp"]}
                                alt="Subscribe to YouTube"
                            />
                            <span className="ps-14">Subscribe to YouTube</span>
                        </a>

                    </Stack>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gy-24 gx-md-16 gy-md-40 g-lg-40">
                        {postsToShow?.map((video, i) => {
                            if (i === 3) {
                                return (
                                    <React.Fragment key={i}>
                                        <Col>
                                            <VideoCard hideVideoIcon={true} video={video} custom={i} className="h-100" />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <BookValuation
                                                moduleData={{
                                                    Title: "Find out what <strong>marketing support</strong> Exclusive Links provides",
                                                    Text: "When listing your property for sale or rent we need to ensure maximum quality exposure is achieved."
                                                }}
                                                Buttons={[
                                                    {
                                                        id: "1",
                                                        btnClass: "btn-secondary",
                                                        label: "List your property",
                                                        // icon: "personIcon",
                                                        manualLink: `${Routes.ListYourProperty}`
                                                    }
                                                ]}
                                            />
                                        </Col>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <Col key={i}>
                                        <VideoCard hideVideoIcon={true} video={video} custom={i} className="h-100" />
                                    </Col>
                                )
                            }
                        })}
                        {postsToShow?.length !== videos?.length &&
                            <Col xs={12} md={12} xxl={12}>
                                <Button
                                    variant="loadMore"
                                    onClick={handleShowMorePosts}
                                    className="w-100"
                                >
                                    Load more
                                </Button>
                            </Col>
                        }
                    </div>
                </ModuleSection>
            </Loader>
        </Layout>
    )
                    }
}

export default VideoLandingPages